import get from 'lodash-es/get'
import Rest from '../Rest'

export default class ContentService extends Rest {
  /**
   * @type {String}
   */
  static resource = '/api/v1/contents'

  static build(vs: any = null) {
    return new this(this.resource, null, vs)
  }

  getMedias(id) {
    return this.get(`${id}/medias`)
  }

  getThemes(classes) {
    return this.get(`/themes`, {classes})
  }

  getClassesPage(path, data) {
    return this.get('/classes/grid', data)
  }

  getClassContents(id) {
    return this.get(`/classes/${id}`)
  }

  uploadLinkMedia(id, link) {
    return this.post(`${id}/media/link`, {link})
  }

  uploadGDriveMedia(id, driveDataDoc) {
    return this.post(`${id}/media/gdrive`, {data: driveDataDoc})
  }

  deleteMedia(mediaId) {
    return this.delete(`/medias/${mediaId}`)
  }

}
